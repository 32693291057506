import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

const instance = axios.create({
   headers: {
      'Content-Type': 'application/json; charset=utf-8',
   }
});

instance.interceptors.request.use(async (config) => {
   return config
}, error => {
   const loader = document.getElementById("nuxt-loading");
   if (loader) loader.remove();
   if (error.response && error.response.status === 401) {
      toast.warning('CEP inválido!')
   }
   return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
   const loader = document.getElementById("nuxt-loading");
   if (loader) loader.remove();
   return response
}, error => {
   const loader = document.getElementById("nuxt-loading");
   if (loader) loader.remove();
   return Promise.reject(error);
})


export default instance;






